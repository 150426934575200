<template>
	<div class="activity_page">
		<div class="headBackground">
			<div class="logo"><img src="@/assets/image/adultTest/logo.png" alt="" /></div>
			<div class="theme"><img src="@/assets/image/adultTest/text.png" alt="" /></div>
		</div>
		<div style="width: 680px; margin: 0 auto">
			<div class="navigation">
				<img :src="left_arrow" class="left_arrow" @click="goHistory()" alt="" />
				<div class="titleText"></div>
			</div>
		</div>
		<div class="status_module">
			<img src="@/assets/image/adultTest/successful-state.png" alt="" />
			<p>恭喜你，預約成功</p>
			<div class="test_number">
				<span class="text">你的考生號為：</span>
				<span class="number">{{ renderData.testCode }}</span>
				<div class="copy_button" @click="copyNumber">複製</div>
			</div>
		</div>

		<div class="card_1">
			<div class="row-box">
				<div>姓名</div>
				<div>{{ renderData.testName }}</div>
			</div>
			<div class="row-box">
				<div>證件英文姓名</div>
				<div>{{ renderData.testNamePinyin }}</div>
			</div>
			<div class="row-box">
				<div>手機號</div>
				<div>+{{ renderData.areaCode }}-{{ renderData.phone }}</div>
			</div>
			<div class="row-box">
				<div>電郵</div>
				<div>{{ renderData.email }}</div>
			</div>
			<div class="row-box">
				<div>預約时间</div>
				<div>{{ renderData.testDate.split(' ')[0] }} {{ renderData.testStartTime }}</div>
			</div>
		</div>
		<div class="card_2">
			<div class="row-box">
				<div>證件類型</div>
				<div>{{ renderData.idType === 0 ? '身份證' : renderData.idType == 1 ? '護照' : null }}</div>
			</div>
			<div class="row-box">
				<div>證件號</div>
				<div>{{ renderData.idNo }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	name: 'SuccessState',
	components: {
		[Toast.name]: Toast
	},
	data() {
		return {
			left_arrow: require('@/assets/image/adultTest/left_arrow.png'),
			right_arrow: require('@/assets/image/adultTest/right_arrow.png'),
			renderData: {}
		};
	},
	mounted() {
		this.renderData = this.$route.params.data;
	},
	methods: {
		goHistory() {
			this.$router.go(-1);
		},
		copyNumber() {
			const cInput = document.createElement('input');
			cInput.value = this.renderData.testCode;
			document.body.appendChild(cInput);
			cInput.select(); // 选取文本域内容;
			document.execCommand('Copy');
			Toast.success('考生號復製成功');
			/// 复制成功后再将构造的标签 移除
			cInput.remove();
		}
	}
};
</script>

<style lang="scss" scoped>
.navigation {
	margin: 32px auto 46px auto;
	display: flex;
	align-items: center;
	.left_arrow {
		width: 24px;
		height: 24px;
		margin-right: 16px;
		cursor: pointer;
	}
	.titleText {
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		line-height: 19px;
	}
}

html,
body {
	min-width: 980px;
}
.activity_page {
	width: 100vw;
	min-height: 100vh;
	display: table;
	background: #f1f4fe;
	.headBackground {
		height: 296px;
		background: url('~@/assets/image/adultTest/headBackground.jpg') no-repeat;
		background-size: 100% 296px;
		background-position: center center;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			background: #ffffff;
			box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
			border-radius: 16px;
			padding: 10px 16px;
			box-sizing: border-box;
			width: 80px;
			height: 80px;
			margin-right: 24px;
			img {
				width: 48px;
				height: 60px;
			}
		}
		.theme {
			img {
				width: 571px;
			}
		}
	}
	.status_module {
		margin-top: 8px;
		img {
			width: 148px;
		}
		p {
			margin: -28px 0 14px 0;
			font-size: 16px;
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: 19px;
		}
		.test_number {
			font-size: 14px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			.number {
				color: #333333;
			}
			.copy_button {
				margin-left: 8px;
				width: 48px;
				height: 20px;
				background: #122ab0;
				border-radius: 46px;
				font-size: 12px;
				font-weight: 400;
				color: #ffffff;
				line-height: 20px;
				cursor: pointer;
			}
		}
	}
	.card_1 {
		width: 680px;
		height: 340px;
		padding: 0 24px 0 28px;
		margin: 28px auto 0 auto;
		background: #ffffff;
		border-radius: 8px;
		box-sizing: border-box;
		font-size: 14px;
		color: #333333;
		font-family: PingFang SC-Regular, PingFang SC;
		.row-box {
			height: 67px;
			border-bottom: 1px solid #f2f2f2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 68px;
		}
		.row-box:last-child {
			border-bottom: none;
		}
	}
	.card_2 {
		width: 680px;
		height: 136px;
		padding: 0 24px 0 28px;
		margin: 24px auto 90px auto;
		background: #ffffff;
		border-radius: 8px;
		box-sizing: border-box;
		font-size: 14px;
		color: #333333;
		font-family: PingFang SC-Regular, PingFang SC;
		.row-box {
			height: 67px;
			border-bottom: 1px solid #f2f2f2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 68px;
		}
		.row-box:last-child {
			border-bottom: none;
		}
	}
	.row-box {
		height: 68px;
		border-bottom: 1px solid #f2f2f2;
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 68px;
		box-sizing: border-box;
	}
}
</style>
